<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Skladdan chiqimlar registratsiyasi</h3>
          </div>
        </div>
        <div class="card-body">
          <v-container fluid>
            <v-row justify="space-between">
              <v-col cols="4" class="pl-0 py-0">
                <v-text-field
                  :error-messages="reg_nomerErrors"
                  v-model="reg_nomer"
                  label="Registratsiya raqami"
                  outlined
                  dense
                  :success="reg_nomer !== ''"
                  @input="$v.reg_nomer.$touch()"
                  @blur="$v.reg_nomer.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pl-0 py-0">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :error-messages="oper_dateErrors"
                      label="Sana"
                      :value="oper_date | dateFilter"
                      readonly
                      dense
                      append-icon="event"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      :success="oper_date !== ''"
                      @input="$v.oper_date.$touch()"
                      @blur="$v.oper_date.$touch()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="oper_date"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4" class="pl-0 py-0">
                <v-select
                  @change="getExportedWarehouse(exported_warehouse.id)"
                  :error-messages="exported_warehouseErrors"
                  v-model="exported_warehouse"
                  :items="allSklads"
                  :success="exported_warehouse !== ''"
                  item-text="sklad_name"
                  item-value="value"
                  return-object
                  outlined
                  dense
                  label="Sklad"
                  @input="$v.exported_warehouse.$touch()"
                  @blur="$v.exported_warehouse.$touch()"
                ></v-select>
              </v-col>
              <v-col cols="6" class="pl-0 py-0">
                <v-autocomplete
                  :error-messages="contragentErrors"
                  :items="contragentsList"
                  v-model="contragent"
                  @change="getContracts"
                  v-debounce:400ms="myFn"
                  item-text="full_name"
                  :success="
                    contragent !== '' &&
                    contragent !== null &&
                    contragent !== undefined
                  "
                  item-value="id"
                  label="Kontragent"
                  outlined
                  dense
                  @input="$v.contragent.$touch()"
                  @blur="$v.contragent.$touch()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" class="pl-0 py-0 pb-2">
                <v-combobox
                  :error-messages="contragent_contractErrors"
                  :items="contragent_contractLists"
                  v-model="contragent_contract"
                  item-text="reg_number"
                  :success="
                    contragent_contract !== '' &&
                    contragent_contract !== null &&
                    contragent_contract !== undefined
                  "
                  item-value="id"
                  dense
                  label="Kontragent Shartnomasi"
                  outlined
                  @input="$v.contragent_contract.$touch()"
                  @blur="$v.contragent_contract.$touch()"
                ></v-combobox>
              </v-col>
              <v-col cols="12" class="pl-0 py-0">
                <v-textarea
                  v-model="comment"
                  :success="comment !== ''"
                  name="input-7-1"
                  label="Comment"
                  outlined
                  dense
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="pl-0 pb-2">
                <v-row align="center">
                  <v-col class="d-flex" cols="12" sm="12">
                    <v-autocomplete
                      v-model="productsForExport"
                      :items="storedproductsForExport"
                      item-text="name"
                      item-value="id"
                      label="Nomenklatura"
                      outlined
                      class="mx-1 w-30"
                      return-object
                      dense
                    ></v-autocomplete>
                    <v-text-field
                      disabled
                      v-if="productCondition == null"
                      :value="productCondition"
                      label="Holat"
                      outlined
                      dense
                      class="mx-1"
                      style="width: 15%"
                    ></v-text-field>
                    <v-text-field
                      v-model="amount"
                      label="Soni"
                      v-mask="'####'"
                      outlined
                      :error="amount > limit"
                      :error-messages="
                        amount > limit ? 'xato max:' + limit : ''
                      "
                      dense
                      class="mx-1"
                      style="width: 8%"
                    ></v-text-field>
                    <v-text-field
                      disabled
                      :value="price | mask"
                      label="Narxi"
                      outlined
                      dense
                      class="mx-1 w-20"
                    ></v-text-field>
                    <v-text-field
                      disabled
                      :value="amount * price || '' | mask"
                      label="Jami"
                      outlined
                      dense
                      class="mx-1 w-20"
                    ></v-text-field>
                    <v-text-field
                      disabled
                      :value="amount * ndsPerItem || '' | mask"
                      label="Nds summa"
                      outlined
                      dense
                      class="mx-1 w-20"
                    ></v-text-field>
                    <v-text-field
                      disabled
                      :value="amount * ndsPerItem + amount * price || '' | mask"
                      label="Summa(NDS)"
                      outlined
                      dense
                      class="mx-1 w-20"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <div>
                  <v-row justify="end" class="text-right">
                    <v-col>
                      <v-btn @click="remove" color="error" class="mx-1" dense
                        >O'chirish</v-btn
                      >
                      <v-btn @click="add" dense color="success">Qo'shish</v-btn>
                    </v-col>
                  </v-row>
                </div>
                <v-data-table
                  v-if="items.length !== 0"
                  :headers="headers"
                  :items="items"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:[`item.price`]="{ item }">
                    {{ item.price | mask }}
                  </template>
                  <template v-slot:[`item.total`]="{ item }">
                    {{ item.total | mask }}
                  </template>
                  <template v-slot:[`item.nds`]="{ item }">
                    {{ item.nds | mask }}
                  </template>
                  <template v-slot:[`item.nds_summa`]="{ item }">
                    {{ item.nds_summa | mask }}
                  </template>
                  <template v-slot:[`item.total_withnds_summa`]="{ item }">
                    {{ item.total_withnds_summa | mask }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
          <div class="d-flex justify-content-start border-top pt-10">
            <div class="mr-2">
              <button
                type="button"
                class="
                  btn btn-light-primary
                  font-weight-bold
                  text-uppercase
                  px-9
                  py-4
                "
                @click="save"
                :disabled="newSoldLoading"
              >
                <i v-if="newSoldLoading" class="el-icon-loading"></i>Saqlash
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>

<script>
import { CurrencyDirective } from 'vue-currency-input'
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { required } from 'vuelidate/lib/validators'
export default {
  directives: { currency: CurrencyDirective },
  data() {
    return {
      newSoldLoading: false,
      seller_to_corpcard_cash: '',
      responsible: '',
      show: false,
      value: null,
      placement_type: '',
      headers: [
        { text: 'Nomenklatura', value: 'name' },
        { text: 'Holati', value: 'condition' },
        { text: 'soni', value: 'amount' },
        { text: 'Narxi', value: 'price' },
        { text: 'Summa', value: 'total' },
        { text: 'NDS summa', value: 'nds_summa' },
        { text: 'Summa(NDS)', value: 'total_withnds_summa' }
      ],
      categoryId: null,
      tab: '',
      menu2: false,
      oper_date: '',
      reg_nomer: '',
      items: [],
      import_group_id: '',
      product_condition: '',
      productsForExport: '',
      productCondition: '',
      price: '',
      amount: '',
      ndsPerItem: '',
      comment: '',
      exported_warehouse: '',
      limit: 0,
      ndsSumma: 0,
      contragentsList: [],
      contragent: '',
      contragent_contractLists: [],
      contragent_contract: ''
    }
  },
  validations: {
    oper_date: {
      required
    },
    reg_nomer: {
      required
    },
    exported_warehouse: {
      required
    },
    contragent: {
      required
    },
    contragent_contract: {
      required
    }
  },
  created() {
    this.$store.dispatch('getAllSkladList')
    this.getCategoryGroupNames()
  },
  watch: {
    productsForExport: function (val) {
      this.price = ''
      if (val !== null && val !== '') {
        this.amount = ''
        this.limit = val.remain_amount_in_warehouse
        // eslint-disable-next-line no-extra-boolean-cast
        if (Boolean(val.product_condition)) {
          this.productCondition = val.product_condition.name
        } else {
          this.product_condition = ''
        }
        this.price = val.price
        this.ndsPerItem = val.nds_per_item
      }
    }
  },
  computed: {
    contragent_contractErrors() {
      const errors = []
      if (!this.$v.contragent_contract.$dirty) return errors

      !this.$v.contragent_contract.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    ...mapGetters(['allCategoryGroupNames', 'getCategoryGroupData']),
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    storedproductsForExport() {
      const data = this.$store.state.requests.storedproductsForExport
      data.forEach((x) => {
        x.name =
          x.import_category.name +
          ' - kirim narxi: ' +
          x.price +
          ' - ' +
          this.calcAmount(x)
      })
      return data
    },
    exported_warehouseErrors() {
      const errors = []
      if (!this.$v.exported_warehouse.$dirty) return errors
      !this.$v.exported_warehouse.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    contragentErrors() {
      const errors = []
      if (!this.$v.contragent.$dirty) return errors

      !this.$v.contragent.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    oper_dateErrors() {
      const errors = []
      if (!this.$v.oper_date.$dirty) return errors
      !this.$v.oper_date.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    reg_nomerErrors() {
      const errors = []
      if (!this.$v.reg_nomer.$dirty) return errors
      !this.$v.reg_nomer.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    allSklads() {
      const data = this.$store.state.requests.allSkladList
      if (data !== undefined && data.length !== undefined) {
        data.forEach((element) => {
          if (element.warehouse_responsible !== null) {
            if (element.responsible_description !== null) {
              element.sklad_name =
                element.name +
                ' | ' +
                element.responsible_description.staffpersonal_description
                  .first_name +
                ' ' +
                element.responsible_description.staffpersonal_description
                  .last_name
            }
          } else {
            element.sklad_name = element.name + ' Masul shaxs kiritilmagan'
          }
        })
        console.log(data)
        return data
      } else return data
    }
  },
  filters: {
    mask: (val) => {
      if (val !== undefined) {
        return val.toLocaleString('es-US')
      }
    },
    floatToNumber: (val) => {
      return Math.floor(val)
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Skladdan chiqimlar registratsiyasi' }
    ])
  },
  methods: {
    calcAmount(x) {
      let totalAmount = this.items
        .filter((item) => item.id == x.id)
        .map((x) => x.amount)
        .reduce((a, b) => a + b, 0)
      if (this.items.find((item) => item.id == x.id)) {
        const amount = x.remain_amount_in_warehouse - totalAmount
        this.limit = amount
        console.log('this.limit', this.limit)
        return amount
      } else {
        this.limit = x.remain_amount_in_warehouse
        console.log('this.limit', this.limit)
        return x.remain_amount_in_warehouse
      }
    },
    getContracts() {
      if (this.contragent !== null) {
        this.$store
          .dispatch('counteragentContracts', this.contragent)
          .then((res) => {
            this.contragent_contractLists = []
            this.contragent_contractLists = [...res]
          })
      }
    },
    myFn(val) {
      const data = {
        name: val
      }
      this.$store.dispatch('counteragentsSearch', data).then((res) => {
        this.contragentsList = []
        this.contragentsList = [...res]
      })
    },
    getExportedWarehouse(payload) {
      this.productsForExport = ''
      this.amount = ''
      this.price = ''
      this.ndsPerItem = 0
      this.limit = 0
      this.productCondition = ''
      this.$store.dispatch('getStoredproductsForExport', payload)
    },
    ...mapActions(['getCategoryGroupNames']),
    remove() {
      this.items.reverse()
      if (this.items.length == 0) {
        Swal.fire({
          title: "Malumotlar to'liq emas",
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-error'
        })
      }
      if (this.items.length == 2) {
        this.items = []
        this.ndsSumma = 0
      }
      if (this.items.length !== 0) {
        this.ndsSumma -= this.items[this.items.length - 1].total_withnds_summa
        this.items[0].total_withnds_summa -=
          this.items[this.items.length - 1].total_withnds_summa
        this.items.splice(-1, 1)
      }
      this.items.reverse()
    },
    add() {
      if (this.productsForExport !== '' && this.amount !== '') {
        if (this.amount <= this.limit) {
          const data = {
            id: this.productsForExport.id,
            name: this.productsForExport.name,
            condition: this.productCondition,
            import_group_id: this.productsForExport.import_group.id,
            import_category_id: this.productsForExport.import_category.id,
            exported_product_id: this.productsForExport.id,
            amount: parseInt(this.amount, 10),
            price: this.price,
            total: this.amount * this.price,
            nds: this.productsForExport.nds,
            truck_id: null,
            nds_per_item: (this.productsForExport.nds * this.price) / 100,
            nds_summa: this.amount * this.ndsPerItem,
            total_withnds_summa:
              this.amount * this.ndsPerItem + this.amount * this.price
          }
          data.name = data.name.substring(0, data.name.indexOf('narxi'))
          this.ndsSumma += data.total_withnds_summa
          this.items.reverse()
          if (this.items.length == 0) {
            const data = {
              name: 'Umumiy summa',
              total_withnds_summa: 0
            }
            this.items.push(data)
          }
          this.items.push(data)
          this.items[0].total_withnds_summa = this.ndsSumma
          this.items.reverse()
          this.products_services = {}
          this.amount = ''
        } else {
          Swal.fire({
            title: "Malumotlar to'liq emas",
            text: 'Nomenklatura soni xato kiritilingan!',
            icon: 'error',
            confirmButtonClass: 'btn btn-error'
          })
        }
      } else {
        Swal.fire({
          title: "Malumotlar to'liq emas",
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-error'
        })
      }
    },
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: 'Xato toldirilgan',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        const data = {
          document: {
            operation_type: 'REAL',
            oper_date: this.oper_date,
            reg_number: this.reg_nomer,
            exported_warehouse: this.exported_warehouse.id,
            contragent: this.contragent,
            contragent_contract: this.contragent_contract.id,
            comment: this.comment
          },
          products_services: []
        }
        if (this.items.length !== 0) {
          this.items.forEach((x) => {
            delete x.id
            delete x.name
            delete x.condition
          })
          this.items.splice(-1, 1)
          data.products_services = [...this.items]
          this.newSoldLoading = true
          this.$store
            .dispatch('createGoodsservicesExport', data)
            .then(() => {
              this.newSoldLoading = false
              this.$v.$reset()
            })
            .catch((err) => {
              this.newSoldLoading = false
              console.error(err)
            })
        } else {
          Swal.fire({
            title: '',
            text: "Ma'lumotlar yuborilmadi!",
            icon: 'error',
            confirmButtonClass: 'btn btn-secondary'
          })
        }
      }
    }
  }
}
</script>
<style scoped>
.w-30 {
  width: 32%;
}
.w-20 {
  width: 20%;
}
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
  min-height: 300px;
}
</style>
